import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import { getMethod, new_domain } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Layout from "./Layout";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import moment from "moment";
import SweetAlert from "sweetalert2";
import { affected_products, formatCurrency } from "../utils/Helper";

const ReportProvider = () => {
  const [loading, setLoading] = useState(true);
  const currentDate = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [refr, setRefr] = useState(true);
  const [appName, setAppName] = useState([]);
  const [searchAppName, setSearchAppName] = useState("");

  useEffect(() => {
    (async () => {
      let resAppName = await getMethod(`/app-name`);
      if (resAppName?.status === "success") {
        setAppName(resAppName.data);
      }
      setLoading(false);
    })();
  }, [refr]);

  const searchSubmitHandler = async () => {
    if (loading) {
      SweetAlert.fire({
        icon: "warning",
        width: 300,
        title: "Sorry!",
        text: "Please wait a moment!",
      });
      return;
    }
    setLoading(true);
    try {
      let app_id = searchAppName === "all" ? "" : searchAppName;
      if (!searchAppName) {
        throw new Error("Please select app!");
      }

      await fetch(
        new_domain +
          `/slot/provider-reports?app_name_id=${app_id}&start_date=${startDate}&end_date=${endDate}`
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setData(res.data);
          }
          // if (rest)
        });
    } catch (error) {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text: error.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };

  const totals = useMemo(() => {
    return data.reduce(
      (acc, el) => {
        acc.totalBet += el.total_bet || 0;
        acc.totalPayout += el.total_payout || 0;
        acc.totalProfit += el.total_profit || 0;
        return acc;
      },
      { totalBet: 0, totalPayout: 0, totalProfit: 0 }
    );
  }, [data]);

  const new_providers = useMemo(() => {
    return data.filter((el) => {
      return affected_products.includes(el.p_code);
    });
  }, [data]);

  const old_providers = useMemo(() => {
    return data.filter((el) => {
      return !affected_products.includes(el.p_code);
    });
  }, [data]);

  const new_totals = useMemo(() => {
    return new_providers.reduce(
      (acc, el) => {
        acc.totalBet += el.total_bet || 0;
        acc.totalPayout += el.total_payout || 0;
        acc.totalProfit += el.total_profit || 0;
        return acc;
      },
      { totalBet: 0, totalPayout: 0, totalProfit: 0 }
    );
  }, [new_providers]);

  const old_totals = useMemo(() => {
    return old_providers.reduce(
      (acc, el) => {
        acc.totalBet += el.total_bet || 0;
        acc.totalPayout += el.total_payout || 0;
        acc.totalProfit += el.total_profit || 0;
        return acc;
      },
      { totalBet: 0, totalPayout: 0, totalProfit: 0 }
    );
  }, [old_providers]);

  return (
    <>
      <Layout>
        <Breadcrumbs title="Provider Report" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4">
                  <Col sm="12">
                    <Form>
                      <Row className="justify-content-end">
                        <Col md="4 px-1 mb-2">
                          <DateRangeFormat
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            type="select"
                            name="select"
                            className="form-control"
                            onChange={(e) => setSearchAppName(e.target.value)}
                          >
                            <option value="">Select App</option>
                            <option value="all">All App</option>
                            {appName.length > 0 &&
                              appName.map((el, index) => (
                                <option
                                  key={`app_name_key_${index}_${el.id}`}
                                  value={el.id}
                                >
                                  {el.name}
                                </option>
                              ))}
                          </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn btn-primary"
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Provider</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Bet Amount</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Bet Payout</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Bet Profit</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {new_providers.length > 0 && (
                          <>
                            {new_providers.map((bal, i) => (
                              <tr key={i}>
                                <th className="text-center" scope="row">
                                  {i + 1}.
                                </th>
                                <th className="text-center" scope="row">
                                  {bal.p_name}
                                </th>
                                <td style={{ textAlign: "right" }}>
                                  {bal?.total_bet?.toLocaleString("en-us")}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {bal?.total_payout?.toLocaleString("en-us")}
                                </td>
                                <td
                                  style={{ textAlign: "right" }}
                                  className={`${
                                    bal?.total_profit > 0 ? "" : "text-danger"
                                  }`}
                                >
                                  {bal?.total_profit?.toLocaleString("en-us")}
                                </td>
                              </tr>
                            ))}
                            <tr className="bg-primary">
                              <td
                                colSpan={2}
                                className="text-center text-white"
                              >
                                Main Total
                              </td>
                              <td className="text-end text-white">
                                {formatCurrency(new_totals?.totalBet)}
                              </td>
                              <td className="text-end text-white">
                                {formatCurrency(new_totals?.totalPayout)}
                              </td>
                              <td
                                className={`text-end ${
                                  new_totals?.totalProfit > 0
                                    ? "text-white"
                                    : "text-white"
                                }`}
                              >
                                {formatCurrency(new_totals?.totalProfit)}
                              </td>
                            </tr>
                          </>
                        )}
                        {old_providers.length > 0 && (
                          <>
                            {old_providers.map((bal, i) => (
                              <tr key={i}>
                                <th className="text-center" scope="row">
                                  {i + 1}.
                                </th>
                                <th className="text-center" scope="row">
                                  {bal.p_name}
                                </th>
                                <td style={{ textAlign: "right" }}>
                                  {bal?.total_bet?.toLocaleString("en-us")}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {bal?.total_payout?.toLocaleString("en-us")}
                                </td>
                                <td
                                  style={{ textAlign: "right" }}
                                  className={`${
                                    bal?.total_profit > 0 ? "" : "text-danger"
                                  }`}
                                >
                                  {bal?.total_profit?.toLocaleString("en-us")}
                                </td>
                              </tr>
                            ))}
                            <tr className="bg-primary">
                              <td
                                colSpan={2}
                                className="text-center text-white"
                              >
                                Other Total
                              </td>
                              <td className="text-end text-white">
                                {formatCurrency(old_totals?.totalBet)}
                              </td>
                              <td className="text-end text-white">
                                {formatCurrency(old_totals?.totalPayout)}
                              </td>
                              <td
                                className={`text-end ${
                                  old_totals?.totalProfit > 0
                                    ? "text-white"
                                    : "text-white"
                                }`}
                              >
                                {formatCurrency(old_totals?.totalProfit)}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                      <tfoot>
                        <tr className="bg-dark">
                          <td colSpan={2} className="text-center text-white">
                            Grand Total
                          </td>
                          <td className="text-end text-white">
                            {formatCurrency(totals?.totalBet)}
                          </td>
                          <td className="text-end text-white">
                            {formatCurrency(totals?.totalPayout)}
                          </td>
                          <td
                            className={`text-end ${
                              totals?.totalProfit > 0
                                ? "text-white"
                                : "text-danger"
                            }`}
                          >
                            {formatCurrency(totals?.totalProfit)}
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </TableScroll>
                  {loading && <Loader />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default ReportProvider;
