import React, { Fragment, useState, useEffect } from "react";
import { MENUITEMS } from "./menu";
import {
  ArrowRight,
  ArrowLeft,
  Grid,
  Key,
  UserPlus,
  Folder,
} from "react-feather";
import { Link } from "react-router-dom";
import configDB from "../../data/customizer/config";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const { t } = useTranslation();
  const [sidebartoogle, setSidebartoogle] = useState(true);
  const [filterRoleSidebar, setFilterRoleSidebar] = useState([]);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const authStatus = useSelector((state) => state.auth.value);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "material-type" ||
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "advance-layout"
      )
        document.querySelector(".sidebar-main").className =
          "sidebar-main hovered";
    } else {
      if (document.getElementById("sidebar-main"))
        document.querySelector(".sidebar-main").className = "sidebar-main";
    }
  };
  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none");

    window.addEventListener("resize", handleResize);
    handleResize();
    const currentUrl = window.location.pathname;
    MENUITEMS.map((items) => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl) setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [mainmenu]);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const setNavActive = (item) => {
    MENUITEMS.map((menuItems) => {
      menuItems.Items.filter((Items) => {
        if (Items !== item) {
          Items.active = false;
          document.querySelector(".bg-overlay1").classList.remove("active");
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true;
          document.querySelector(".sidebar-link").classList.add("active");
        }
        if (Items.children) {
          Items.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
      document
        .querySelector(".mega-menu-container")
        .classList.remove("d-block");
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper ";
      }
    }

    if (!item.active) {
      MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }

    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      document.querySelector(".right-arrow").classList.add("d-none");
      document.querySelector(".left-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector(".left-arrow").classList.remove("d-none");
    }
  };

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector(".left-arrow").classList.add("d-none");
      document.querySelector(".right-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector(".right-arrow").classList.remove("d-none");
    }
  };

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-link").classList.remove("active");
  };

  const activeClass = (menuActiveClass) => {
    if (menuActiveClass === true) {
      document.querySelector(".sidebar-link").classList.add("active");
      document.querySelector(".bg-overlay1").classList.add("active");
    } else {
      document.querySelector(".sidebar-link").classList.remove("active");
      document.querySelector(".bg-overlay1").classList.remove("active");
    }
  };

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper bg-dark close_icon ";
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper bg-dark";
    }
  };

  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className =
      "sidebar-wrapper bg-dark close_icon";
  };

  useEffect(() => {
    const getAuth = localStorage.getItem("admin_auth");
    if (getAuth) {
      let temp = [];
      const token = JSON.parse(getAuth);
      switch (token.user_role) {
        case "staff":
          if (MENUITEMS.length > 0) {
            MENUITEMS.map((content) => {
              if (content.Items.length > 0) {
                content.Items.map((item) => {
                  if (item.title === "Dashboard") {
                    temp.push(item);
                  } else if (item.title === "Users") {
                    temp.push(item);
                  } else if (item.title === "Create User") {
                    temp.push({
                      path: "/admin/create-user",
                      title: "Create User",
                      type: "link",
                      icon: UserPlus,
                    });
                  } else if (item.title === "Deposit") {
                    temp.push(item);
                  } else if (item.title === "Withdraw") {
                    temp.push(item);
                  } else if (item.title === "Manual Cashier") {
                    temp.push(item);
                  } else if (item.title === "Trans History") {
                    temp.push(item);
                  } else if (item.title === "Balance Trans") {
                    temp.push(item);
                  } else if (item.title === "Reports") {
                    const tempItem = item.children.filter(
                      (i) => i.title !== "Streamers Report"
                    );
                    temp.push({
                      title: "Reports",
                      icon: Folder,
                      path: `#`,
                      type: "sub",
                      children: [...tempItem],
                    });
                  } else if (item.title === "Sport Betting") {
                    temp.push(item);
                  } else if (item.title === "Change Password") {
                    temp.push(item);
                  } else if (item.title === "Games Betting") {
                    temp.push(item);
                  }
                });
              }
            });
            setFilterRoleSidebar([{ Items: temp }]);
          }
          break;
        case "streamer":
          if (MENUITEMS.length > 0) {
            MENUITEMS.map((content) => {
              if (content.Items.length > 0) {
                content.Items.map((item) => {
                  if (item.title === "Users") {
                    temp.push(item);
                  } else if (item.title === "Deposit") {
                    temp.push(item);
                  } else if (item.title === "Withdraw") {
                    temp.push(item);
                  } else if (item.title === "Change Password") {
                    temp.push({ ...item.children[1], icon: Key });
                  }
                });
              }
            });
            setFilterRoleSidebar([{ Items: temp }]);
          }
          break;
        case "partner":
          if (MENUITEMS.length > 0) {
            MENUITEMS.map((content) => {
              if (content.Items.length > 0) {
                content.Items.map((item) => {
                  if (item.title === "Users") {
                    temp.push(item);
                  } else if (item.title === "Manual Cashier") {
                    temp.push(item);
                  } else if (item.title === "Trans History") {
                    temp.push(item);
                  } else if (item.title === "Balance Trans") {
                    temp.push(item);
                  } else if (item.title === "Reports") {
                    const tempItem = item.children.filter(
                      (i) => i.title !== "Streamers Report"
                    );
                    temp.push({
                      title: "Reports",
                      icon: Folder,
                      path: `#`,
                      type: "sub",
                      children: [...tempItem],
                    });
                  } else if (item.title === "Change Password") {
                    temp.push({ ...item.children[1], icon: Key });
                  } else if (item.title === "Games Betting") {
                    temp.push(item);
                  }
                });
              }
            });
            setFilterRoleSidebar([{ Items: temp }]);
          }
          break;
        default:
          setFilterRoleSidebar(MENUITEMS);
      }
    }
  }, [MENUITEMS, authStatus]);

  return (
    <Fragment>
      <div
        className={`bg-overlay1`}
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div className="sidebar-wrapper bg-dark" id="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link to={`/`}>
            <img
              className="img-fluid for-light"
              style={{ width: "64px" }}
              src={require("../../assets/images/logo/logo.png")}
              alt=""
            />
            <img
              className="img-fluid for-dark"
              style={{ width: "64px" }}
              src={require("../../assets/images/logo/logo.png")}
              alt=""
            />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}>
            <i className="fa fa-angle-left"></i>
          </div>
          <div
            className="toggle-sidebar"
            onClick={() => openCloseSidebar(sidebartoogle)}
          >
            <Grid className="status_toggle middle sidebar-toggle" />
          </div>
        </div>
        <div className="logo-icon-wrapper">
          <Link to={`/`}>
            <img
              className="img-fluid"
              style={{ width: "40px" }}
              src={require("../../assets/images/logo/logo.png")}
              alt=""
            />
          </Link>
        </div>
        <nav className="sidebar-main" id="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}>
            <ArrowLeft />
          </div>
          <div id="sidebar-menu">
            <ul className="sidebar-links custom-scrollbar">
              <li className="back-btn">
                <div className="mobile-back text-end">
                  <span>{"Back"}</span>
                  <i
                    className="fa fa-angle-right ps-2"
                    style={{ color: "rgba(255, 255, 255, 0.7)" }}
                    aria-hidden="true"
                  ></i>
                </div>
              </li>
              {filterRoleSidebar.map((Item, i) => (
                <Fragment key={i}>
                  {Item.Items.map((menuItem, i) => (
                    <li className="sidebar-list" key={i}>
                      {menuItem.type === "sub" ? (
                        <a
                          href="javascript"
                          className={`sidebar-link sidebar-title ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                            setNavActive(menuItem);
                            activeClass(menuItem.active);
                          }}
                        >
                          <menuItem.icon
                            style={{ color: "rgba(255, 255, 255, 0.7)" }}
                          />
                          <span
                            style={{
                              fontWeight: "600",
                              color: "rgba(255, 255, 255, 0.7)",
                            }}
                          >
                            {t(menuItem.title)}
                          </span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                          <div className="according-menu">
                            {menuItem.active ? (
                              <i
                                className="fa fa-angle-down"
                                style={{ color: "rgba(255, 255, 255, 0.7)" }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-angle-right"
                                style={{ color: "rgba(255, 255, 255, 0.7)" }}
                              ></i>
                            )}
                          </div>
                        </a>
                      ) : (
                        ""
                      )}

                      {menuItem.type === "link" ? (
                        <Link
                          to={menuItem.path + "/"}
                          className={`sidebar-link sidebar-title link-nav  ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={() => toggletNavActive(menuItem)}
                        >
                          <menuItem.icon
                            style={{ color: "rgba(255, 255, 255, 0.7)" }}
                          />
                          <span
                            style={{
                              fontWeight: "600",
                              color: "rgba(255, 255, 255, 0.7)",
                            }}
                          >
                            {t(menuItem.title)}
                          </span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : (
                        ""
                      )}

                      {menuItem.children ? (
                        <ul
                          className="sidebar-submenu"
                          style={
                            menuItem.active
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {menuItem.children.map((childrenItem, index) => {
                            return (
                              <li key={index}>
                                {childrenItem.type === "sub" ? (
                                  <a
                                    href="javascript"
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      toggletNavActive(childrenItem);
                                    }}
                                    style={{
                                      fontWeight: "600",
                                      color: "rgba(255, 255, 255, 0.7)",
                                    }}
                                  >
                                    {t(childrenItem.title)}
                                    <span
                                      className="sub-arrow"
                                      style={{
                                        color: "rgba(255, 255, 255, 0.7)",
                                      }}
                                    >
                                      <i
                                        className="fa fa-chevron-right"
                                        style={{
                                          color: "rgba(255, 255, 255, 0.7)",
                                        }}
                                      ></i>
                                    </span>
                                    <div className="according-menu">
                                      {childrenItem.active ? (
                                        <i
                                          className="fa fa-angle-down"
                                          style={{
                                            color: "rgba(255, 255, 255, 0.7)",
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-angle-right"
                                          style={{
                                            color: "rgba(255, 255, 255, 0.7)",
                                          }}
                                        ></i>
                                      )}
                                    </div>
                                  </a>
                                ) : (
                                  ""
                                )}

                                {childrenItem.type === "link" ? (
                                  <Link
                                    to={childrenItem.path + "/"}
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      toggletNavActive(childrenItem)
                                    }
                                    style={{
                                      fontWeight: "600",
                                      color: "rgba(255, 255, 255, 0.7)",
                                    }}
                                  >
                                    {t(childrenItem.title)}
                                  </Link>
                                ) : (
                                  ""
                                )}

                                {childrenItem.children ? (
                                  <ul
                                    className="nav-sub-childmenu submenu-content"
                                    style={
                                      childrenItem.active
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    {childrenItem.children.map(
                                      (childrenSubItem, key) => (
                                        <li key={key}>
                                          {childrenSubItem.type === "link" ? (
                                            <Link
                                              to={childrenSubItem.path + "/"}
                                              className={`${
                                                childrenSubItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                toggletNavActive(
                                                  childrenSubItem
                                                )
                                              }
                                            >
                                              {t(childrenSubItem.title)}
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </Fragment>
              ))}
            </ul>
          </div>
          <div
            className="right-arrow"
            style={{ color: "rgba(255, 255, 255, 0.7)" }}
            onClick={scrollToRight}
          >
            <ArrowRight style={{ color: "rgba(255, 255, 255, 0.7)" }} />
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default Sidebar;
